<div id="layout" class="flex flex-col h-screen max-h-screen">
  <div id="loader" class="overlay" style="display: none;">
    <div class="overlay-content flex p-4 h-[50vh] items-center justify-center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="w-full py-4 flex flex-row justify-center bg-gray-200 relative" id="header">
    <img src="assets/gt_company_logo.png" alt="logo"/>
    <div class="absolute right-4 flex flex-col">
      <b *ngIf="user">{{user.email}}</b>
      <button *ngIf="userService.getUserToken()" (click)="logout()" mat-flat-button color="warn">
        <mat-icon>logout</mat-icon>
        Se déconnecter
      </button>
    </div>
  </div>
  <app-alerts></app-alerts>
  <div class="flex flex-row h-screen max-h-screen">
    <div class="bg-gray-400 w-[12%] flex-grow" id="menu">
      <ul>
        <li routerLink="/">
          <mat-icon>home</mat-icon>
          Accueil
        </li>
        <li routerLink="/products">
          <mat-icon>inventory_2</mat-icon>
          Produits
        </li>
        <li routerLink="/orders">
          <mat-icon>shopping_cart</mat-icon>
          Commandes
        </li>
        <li routerLink="/stores">
          <mat-icon>storefront</mat-icon>
          Boutiques
        </li>
        <li routerLink="/warehouses">
          <mat-icon>warehouse</mat-icon>
          Entrepôts
        </li>
        <li routerLink="/users" *ngIf="userService.isAdmin()">
          <mat-icon>person</mat-icon>
          Utilisateurs
        </li>
      </ul>
      <div id="version" class="w-full text-center">Version: {{ version }}</div>
    </div>
    <div id="content" class="w-[88%] mx-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
