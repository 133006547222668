<h2 class="text-3xl uppercase text-blue-900 my-4 pb-1 font-bold relative">
  <div class="border-b-red-700 border-l-red-700 h-full w-10 border-l-[.3rem] border-b-[.3rem] absolute"></div>
  <span class="ml-[0.6rem]">Liste des utilisateurs</span>
</h2>
<div class="flex justify-end">
  <button mat-flat-button color="primary" (click)="addUser()"><mat-icon>person_add</mat-icon> Ajouter un utilisateur</button>
</div>
<table mat-table [dataSource]="usersDataSource">
  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef>Nom</th>
    <td mat-cell *matCellDef="let user"> {{ user.lastName }}</td>
  </ng-container>
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef>Prénom</th>
    <td mat-cell *matCellDef="let user"> {{ user.firstName }}</td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Adresse email</th>
    <td mat-cell *matCellDef="let user"> {{ user.email }}</td>
  </ng-container>
  <ng-container matColumnDef="grade">
    <th mat-header-cell *matHeaderCellDef>Admin</th>
    <td mat-cell *matCellDef="let user">
      <div style="{{'color: ' + (user.roles.includes('ROLE_ADMIN') ? 'green' : 'red') }}">
        <mat-icon *ngIf="user.roles.includes('ROLE_ADMIN')">admin_panel_settings</mat-icon>
        <mat-icon *ngIf="!user.roles.includes('ROLE_ADMIN')">cancel</mat-icon>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="loader">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let product">
      <div class="flex p-4 h-[50vh] items-center justify-center">
        <mat-spinner></mat-spinner>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let user">
      <div class="flex gap-2">
        <button mat-mini-fab color="primary" (click)="editUser(user)">
          <mat-icon>edit</mat-icon>
        </button>
        @if (userService.isAdmin() && userService.user?.id !== user.id) {
          @if (!isRoleChange(user.id)) {
            <button *ngIf="!user.roles.includes('ROLE_ADMIN')" mat-mini-fab color="warn" class="bg-green-500"
                    (click)="promoteUser(user.id)">
              <mat-icon>arrow_circle_up</mat-icon>
            </button>
            <button *ngIf="user.roles.includes('ROLE_ADMIN')" mat-mini-fab color="warn" class="bg-orange-400"
                    (click)="demoteUser(user.id)">
              <mat-icon>arrow_circle_down</mat-icon>
            </button>
          } @else {
            <button mat-mini-fab class="bg-gray-500">
              <mat-icon class="animate-spin">autorenew</mat-icon>
            </button>
          }
          <button mat-mini-fab color="warn" (click)="deleteUser(user.id, user)">
            <mat-icon>person_remove</mat-icon>
          </button>
        }
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
