export interface Alert {
  dimiss?: boolean;
  id?: string;
  type?: AlertType;
  message?: string;
  options?: AlertOptions
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}

export interface AlertOptions {
  autoClose?: boolean;
  keepAfterRouteChange?: boolean;
}
