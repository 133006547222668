import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {debounceTime, Subject, takeUntil, merge, Observable, of as observableOf, pipe, map} from "rxjs";
import {ProductService} from "../../../services/product.service";
import {MatButton, MatMiniFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {NgIf} from "@angular/common";
import {MatPaginator} from "@angular/material/paginator";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {Product} from "../../../models/Product";
import {MatDialog} from "@angular/material/dialog";
import {ProductFormComponent} from "./form/product-form/product-form.component";
import {ImportProductFormComponent} from "./form/import-product-form/import-product-form.component";
import {AlertService} from "../../../services/alert.service";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {ConfirmPopupComponent} from "../../confirm-popup/confirm-popup.component";
import {MatSort, MatSortHeader, MatSortModule} from "@angular/material/sort";
import {environment} from "../../../../src/environments/environment";
import {MatChipRow} from "@angular/material/chips";
import {HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {ApiService} from "../../../services/api.service";
import {User} from "../../../models/User";

@Component({
  selector: 'app-products-list-view',
  standalone: true,
  imports: [
    MatButton,
    MatIcon,
    MatTableModule,
    NgIf,
    MatPaginator,
    MatProgressSpinner,
    MatMiniFabButton,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatSort,
    MatSortHeader,
    MatSortModule,
    MatChipRow

  ],
  templateUrl: './products-list-view.component.html',
  styleUrl: './products-list-view.component.scss'
})
export class ProductsListViewComponent implements OnDestroy, AfterViewInit {
  private unsubscribeAll: Subject<any> = new Subject();

  protected productsData: Product[];
  protected productsDataSource: MatTableDataSource<Product> = new MatTableDataSource<Product>([{reference: ""}]);
  protected displayedColumns = ["loader"];
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  searchCtl: FormControl = new FormControl("");

  protected apiAddress = environment.apiAddress;

  protected gridPageSizeArray = environment.gridPageSizeArray || [1, 5, 10, 25, 50];
  protected gridDefaultPageIndex = environment.gridDefaultPageIndex || 0;
  protected gridDefaultPageSize = environment.gridDefaultPageSize || 10;

  protected isLoading = false;


  constructor(private productService: ProductService, private dialog: MatDialog, private alertService: AlertService, private apiService: ApiService) {

    // Grid preload :
    this.productsData = [];
    this.productsDataSource = new MatTableDataSource(this.productsData);
  }

  ngAfterViewInit(){
    if(this.paginator === undefined){
      console.error("Houston, we’ve had a problem");
      // PhpStorm indique "Condition is always false sinces types 'MatPaginator' and 'undefined' have no overlap
      // ... mais ca peux quand meme se produire à l'execution du javascript dans le navigateur.
    }
    this.productsDataSource.paginator = this.paginator;

    this.paginator.page.pipe(takeUntil(this.unsubscribeAll)).subscribe(    {
      next: () => {
        //console.log('rechargement suite action utilisateur sur boutons pages [<] [>] ou la liste Items per page');
        this.gridLoad();
      }
    })

    this.sort.sortChange.pipe(takeUntil(this.unsubscribeAll)).subscribe(    {
      next: () => {
        this.gridLoad(false);
      }
    })

    this.searchCtl.valueChanges.pipe(
      debounceTime(1000)
    ).pipe(takeUntil(this.unsubscribeAll)).subscribe(    {
      next: () => {
        this.gridLoad(false);
      }
    })

    this.gridLoad(); //necessaire pour chargement du nombre total de pages et resultats
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null)
    this.unsubscribeAll.complete()
  }

  addProduct() {
    this.dialog.open(ProductFormComponent).afterClosed()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: data => {
          if (data) {
            this.productService.createProduct({
              reference: data.reference,
              reference_lengow: data.reference_lengow,
              reference_shoppingfeed: data.reference_shoppingfeed,
              eco_tax: data.eco_tax.toString(),
              sorecop: data.sorecop.toString()
            })
              .pipe(takeUntil(this.unsubscribeAll))
              .subscribe({
                next: data => {
                  this.alertService.success("Produit créé avec succès.")
                  this.gridLoad();
                }
              })
          }
        }
      })
  }

  editProduct(product: Product) {
    this.dialog.open(ProductFormComponent, {data: {product: product}}).afterClosed()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
          next: data => {
            if (data) {
              this.productService.updateProduct({
                reference: data.reference,
                reference_lengow: data.reference_lengow,
                reference_shoppingfeed: data.reference_shoppingfeed,
                eco_tax: data.eco_tax.toString(),
                sorecop: data.sorecop.toString()
              }, product.id!).pipe(takeUntil(this.unsubscribeAll))
                .subscribe({
                  next: data => {
                    this.alertService.success("Produit modifié avec succès.")
                    this.gridLoad();
                  }
                })
            }
          }
        }
      )
  }

  deleteProduct(productId: number) {
    this.dialog.open(ConfirmPopupComponent, {data: {question: "Voulez-vous vraiment supprimer ce produit ?"}}).afterClosed()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: confirm => {
          if(confirm) {
            this.productService.deleteProduct(productId)
              .pipe(takeUntil(this.unsubscribeAll))
              .subscribe({
                next: data => {
                  this.alertService.success("Produit supprimé avec succès.")
                  this.gridLoad();
                }
              })
          }
        }
      })
  }

  public loaderShow(){
    //console.log("~ LOADER SHOW ~");
    this.isLoading = true;
    //-- DEV SANS new MatTableDataSource :
    //document.getElementById("loader")!.style.display = "block";
    //-- DEV ORG :
    this.displayedColumns = ["loader"];
    this.productsDataSource  = new MatTableDataSource<Product>([{reference: ""}]);  //... necessaire sinon un loader par ligne precedement chargee
  }

  public loaderHide(){
    //console.log("~ LOADER HIDE ~");
    this.isLoading = false;
    //-- DEV SANS new MatTableDataSource :
    //document.getElementById("loader")!.style.display = "none";
    //-- DEV ORG :
    this.displayedColumns = ["id", "reference", "reference_shoppingfeed", "reference_lengow", "stock", "ecotax", "sorecop", "actions"];
    //(et ne pas modifier productsDataSource)
  }

  private gridLoad(keepPageIndex=true) {
    this.loaderShow();
    if (!this.paginator.pageSize) {
      this.paginator.pageSize = this.gridDefaultPageSize
    }
    if(!keepPageIndex){
      this.paginator.pageIndex = 0;
    }

    let search = this.searchCtl.value;
    if(search === undefined){
      search = "";
    }
    //console.log("~~ EXECUTION gridLoad(), search:"+search);

    this.productService.searchProducts(search, this.paginator, this.sort)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          //console.log("searchProducts subscribe => res :", res);
          this.paginator.length = res.metadata.total
          this.productsData = res.datas;
          this.productsDataSource = new MatTableDataSource(this.productsData);
          this.loaderHide();
        }
        //,error: err => {
        //  this.errorService.showError(err);
        //}
      });
  }


  public exportProduct() {
    //window.location.href = new_url; //pas possible car jwt ;), donc on passe par Angular :
    //this.apiService.get("api/product/export", undefined, {responseType:"text"}).subscribe({
    this.apiService.get("api/product/export", undefined, {responseType:"text", observe: "response"}).subscribe({
      next: (response: any) => {
        //data :
        const blob = new Blob([response.body], { type: 'application/octet-stream' });

        //filename :
        let filename = "export-product.csv";
        const contentDispositionHeader: string = response.headers.get('Content-Disposition');
        let parts: string[];
        if(contentDispositionHeader){
           parts = contentDispositionHeader.split(';');
           filename = parts[1].split('=')[1];
        }

        this.apiService.downloadBlob(blob, filename);
      }
    });
  }

  public importProduct() {
    //only open dialog :
    this.dialog.open(ImportProductFormComponent).afterClosed()
      .pipe(takeUntil(this.unsubscribeAll))
  }

}
