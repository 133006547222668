import {Component, OnDestroy, ViewChild} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {Subject, takeUntil} from "rxjs";
import {WarehouseService} from "../../../services/warehouse.service";
import {Warehouse} from "../../../models/Warehouse";

@Component({
  selector: 'app-warehouse-list-view',
  standalone: true,
  imports: [
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatPaginator,
    MatProgressSpinner,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef
  ],
  templateUrl: './warehouse-list-view.component.html',
  styleUrl: './warehouse-list-view.component.scss'
})
export class WarehouseListViewComponent implements OnDestroy {
  private unsubscribeAll: Subject<any> = new Subject();
  protected warehousesDataSource: MatTableDataSource<Warehouse> = new MatTableDataSource<Warehouse>([{}]);
  displayedColumns: string[] = ["loader"];
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private storeService: WarehouseService) {
    this.storeService.getWarehouses()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (warehouses: any) => {
          this.warehousesDataSource = new MatTableDataSource(warehouses.datas as Warehouse[])
          this.warehousesDataSource.paginator = this.paginator;
          this.displayedColumns = ["id", "code", "name", "country", "actions"]
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null)
    this.unsubscribeAll.complete()
  }
}
