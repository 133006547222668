<div class="flex flex-col w-[33vh] p-4">
  <h3 matDialogTitle>Modifier un utilisateur</h3>
  <span class="text-red-600 mb-4" *ngIf="error">{{error}}</span>
  <mat-form-field appearance="outline">
    <mat-label>Nom</mat-label>
    <input matInput [formControl]="lastName" type="text">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Prénom</mat-label>
    <input matInput [formControl]="firstName" type="text">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Adresse e-mail</mat-label>
    <input matInput [formControl]="email" type="email">
  </mat-form-field>
  <button (click)="submit()" mat-flat-button [disabled]="!isValid() || submitting" color="primary">Modifier un utilisateur</button>
</div>
