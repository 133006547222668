import { Component } from '@angular/core';
import {UserService} from "../../../services/user.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-welcome-view',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './welcome-view.component.html',
  styleUrl: './welcome-view.component.scss'
})
export class WelcomeViewComponent {
  constructor(protected userService: UserService) {
  }
}
