<div class="flex flex-col">
  <h3 matDialogTitle>Connexion</h3>
  <span class="text-red-600 mb-4" *ngIf="error">{{error}}</span>
  <mat-form-field appearance="outline">
    <mat-label>Adresse e-mail</mat-label>
    <input matInput [formControl]="email" type="email">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Mot de passe</mat-label>
    <input matInput [formControl]="password" type="password">
  </mat-form-field>
  <button (click)="submit()" mat-flat-button [disabled]="!isValid() || submitting" color="primary">Se connecter</button>
</div>
