import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {User} from "../models/User";
import {map, Observable, Observer} from "rxjs";
import {AlertService} from "./alert.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userToken?: string;
  user?: User;

  constructor(private api: ApiService, private alertService: AlertService, private router: Router) {
  }

  registerUser(user: User) {
    return this.api.post("api/user/register", user);
  }

  updateUser(user: User, userId: number) {
    return this.api.put("api/user", userId.toString(), user);
  }

  loginUser(email: string, password: string) {
    return this.api.post("api/login_check", {
      email: email,
      password: password
    }).pipe(map((token: any) => {
      this.userToken = token.token as string;
      localStorage.setItem("token", token.token as string);
      this.userInfos().subscribe({
        next: _ => {
          this.alertService.success("Vous êtes maintenant connecté.")
          document.location.reload()
          return token.token;
        }
      })
    }));
  }

  userInfos() {
    if (this.user) {
      return new Observable((observer: Observer<any>) => {
        observer.next(this.user)
        observer.complete()
      })
    }
    return this.api.get("api/user/infos").pipe(map((user: any) => {
      this.user = user as User;
      return user;
    }));
  }

  isAdmin() {
    return this.user?.roles?.includes("ROLE_ADMIN") as boolean;
  }

  requireAdmin() {
    if (this.user) {
      if (!this.isAdmin()) {
        this.router.navigate(["/restricted"])
      }
    }
    this.api.get("api/user/infos").subscribe({
      next: (user: any) => {
        this.user = user as User;
        if (!this.isAdmin()) {
          this.router.navigate(["/restricted"])
        }
        return user;
      }
    });
  }

  getUserToken() {
    if (localStorage.getItem("token")) {
      this.userToken = localStorage.getItem("token") ?? undefined;
    }
    return this.userToken;
  }

  logout() {
    this.userToken = undefined;
    this.user = undefined;
    localStorage.removeItem("token");
    document.location.href = "/"
  }

  getUsers() {
    return this.api.get("api/user");
  }

  validate(id
             :
             number
  ) {
    return this.api.put("api/user/validate", id.toString(), undefined);
  }

  invalidate(id
               :
               number
  ) {
    return this.api.put("api/user/invalidate", id.toString(), undefined);
  }

  promote(id
            :
            number
  ) {
    return this.api.put("api/user/promote", id.toString(), undefined);
  }

  demote(id
           :
           number
  ) {
    return this.api.put("api/user/demote", id.toString(), undefined);
  }

  delete(id
           :
           number
  ) {
    return this.api.delete("api/user", id.toString());
  }

  addUser(user
            :
            User
  ) {
    return this.api.post("api/user", user);
  }
}
