<div class="p-4 w-[40vw]">

  <form class="flex flex-col">
    <h2 matDialogTitle>Importer/modifier des produits</h2>

    <mat-form-field appearance="outline">
      <mat-label>Format attendu</mat-label>
      <textarea matInput readonly>reference;reference_shopping_feed;reference_lengow;eco_tax;sorecop</textarea>
    </mat-form-field>

    <div>
      <mat-card *ngIf="uploadFileProgressBarValue">
        <mat-card-content><ul><li> </li><li>
          <mat-progress-bar [mode]="uploadFileProgressBarMode" [value]="uploadFileProgressBarValue"></mat-progress-bar>
          <span id="file-label">
          </span></li><li> </li></ul>
        </mat-card-content>
      </mat-card>
      <input #uploadFileInput name="product_csv" type="file" hidden><!-- multiple compatible -->
      <br/>
    </div>

    <div>
      <span [style.color]="uploadFileResultMessageColor">{{ uploadFileResultMessageText }}</span>
    </div>

    <button *ngIf="uploadFileProgressBarValue===0" mat-flat-button color="primary" type="button" (click)="onClickFileUpload('api/product/import', this.onResponseFileUpload)">Importer un fichier csv</button>
  </form>

</div>
