export class User {
  id?: number;
  email: string;
  lastName?: string;
  firstName?: string;
  roles?: string[];
  password?: string

  constructor(email: string, lastName: string = "", firstName: string = "") {
    this.email = email;
    this.lastName = lastName;
    this.firstName = firstName;
  }
}
