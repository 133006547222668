import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogTitle} from "@angular/material/dialog";
import {Order} from "../../../models/Order";
import {MatIcon} from "@angular/material/icon";
import {
  MatCell,
  MatCellDef,
  MatColumnDef, MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef, MatRow, MatRowDef,
  MatTable
} from "@angular/material/table";
import moment from 'moment-timezone';
import {Product} from "../../../models/Product";
import {formatDate} from "../../util/date";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-order-view',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatIcon,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatHeaderCell,
    MatCell,
    NgIf
  ],
  templateUrl: './order-view.component.html',
  styleUrl: './order-view.component.scss'
})
export class OrderViewComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {order: Order}) {
  }

  protected readonly parseFloat = parseFloat;

  computeTotal() {
    return this.data.order.product_orders?.reduce((acc, curr) => {
      return acc + (parseFloat(curr.price) + parseFloat(curr.tax)) * curr.quantity;
    }, 0).toFixed(2);
  }

  protected readonly formatDate = formatDate;
}
