import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private api: ApiService) { }

  getWarehouses() {
    return this.api.get("api/warehouse");
  }
}
