import {Component, OnDestroy} from '@angular/core';
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {UserService} from "../../../services/user.service";
import {AlertService} from "../../../services/alert.service";
import {User} from "../../../models/User";
import {NgIf} from "@angular/common";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    MatDialogTitle,
    MatButton,
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss'
})
export class LoginFormComponent implements OnDestroy {
  private unsubscribeAll: Subject<any> = new Subject();
  email: FormControl;
  password: FormControl;
  error?: string;
  submitting: boolean = false;

  constructor(private userService: UserService, private alertService: AlertService) {
    this.email = new FormControl<string>("", [Validators.email, Validators.required]);
    this.password = new FormControl<string>("", [Validators.required]);
  }

  public isValid() {
    return this.email.valid && this.password.valid;
  }

  public submit() {
    this.submitting = true;
    this.error = undefined;
    let user = new User(this.email.value);
    user.password = this.password.value;

    this.userService.loginUser(this.email.value, this.password.value)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        error: error => {
          this.error = error
          this.submitting = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null)
    this.unsubscribeAll.complete()
  }
}
