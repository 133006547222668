import {Component, EventEmitter, Inject, OnDestroy, Output} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {Subject, takeUntil} from "rxjs";
import {UserService} from "../../../services/user.service";
import {AlertService} from "../../../services/alert.service";
import {User} from "../../../models/User";

@Component({
  selector: 'app-edit-form',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatDialogTitle,
    MatFormField,
    MatInput,
    MatLabel,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './edit-user-form.component.html',
  styleUrl: './edit-user-form.component.scss'
})
export class EditUserFormComponent implements OnDestroy {
  private unsubscribeAll: Subject<any> = new Subject();
  email: FormControl;
  lastName: FormControl;
  firstName: FormControl;
  error?: string;
  submitting: boolean = false;

  constructor(private userService: UserService, private alertService: AlertService, private dialogRef: MatDialogRef<EditUserFormComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.email = new FormControl<string>(data.user ? data.user.email : "", [Validators.email, Validators.required]);
    this.lastName = new FormControl<string>(data.user ? data.user.lastName : "", [Validators.required]);
    this.firstName = new FormControl(data.user ? data.user.firstName : "", [Validators.required]);
  }

  public isValid() {
    return this.email.valid && this.lastName.valid && this.firstName.valid;
  }

  public submit() {
    this.submitting = true;
    this.error = undefined;
    let user = new User(this.email.value, this.lastName.value, this.firstName.value);
    this.userService.updateUser(user, this.data.user.id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: value => {
          this.submitting = false;
          this.alertService.success("L'utilisateur a bien été modifié.");
          this.dialogRef.close(value as User)
        },
        error: error => {
          this.error = error
          this.submitting = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null)
    this.unsubscribeAll.complete()
  }
}
