import {Component} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {AlertComponent} from "./alert/alert.component";
import {Alert} from "../../models/Alert";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-alerts',
  standalone: true,
  imports: [
    AlertComponent
  ],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss'
})
export class AlertsComponent {
  private unsubscribeAll: Subject<any> = new Subject();
  protected alerts: Alert[] = []
  constructor(private alertService: AlertService) {
    alertService.onAlert()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: alert => {
          this.alerts.push(alert);
          if(alert.options?.autoClose)
          {
            setTimeout(() => {
              this.alerts.filter(a => a == alert)[0].dimiss = true;
            }, 2000 - 500)
            setTimeout(() => {
              this.alerts = this.alerts.filter(a => a !== alert);
            }, 2000)
          }
        }
      })
  }
}
