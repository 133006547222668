<div class="{{alert.dimiss ? 'opacity-0':'opacity-100'}} ease-in transition duration-500 flex items-center gap-4 w-full p-4 text-white" style="{{'background: ' + getColor()}}">
  @switch (alert.type)
  {
    @case (AlertType.Error) {
      <mat-icon>error</mat-icon>
    }
    @case (AlertType.Warning) {
      <mat-icon>warning</mat-icon>
    }
    @case (AlertType.Info) {
      <mat-icon>info</mat-icon>
    }
    @case (AlertType.Success) {
      <mat-icon>check_circle</mat-icon>
    }
  }
  <div>{{alert.message}}</div>
</div>
