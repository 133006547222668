import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private api: ApiService) { }

  public getOrders()
  {
    return this.api.get("api/order");
  }

  public searchOrders(search: string)
  {
    return this.api.get("api/order/search", undefined, {
      params: {
        search: search
      }
    });
  }
}
