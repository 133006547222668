<div class="flex flex-col w-[33vh] p-4">
  <h3 matDialogTitle>Créer un utilisateur</h3>
  <span class="text-red-600 mb-4" *ngIf="error">{{error}}</span>
  <mat-form-field appearance="outline">
    <mat-label>Nom</mat-label>
    <input matInput [formControl]="lastName" type="text">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Prénom</mat-label>
    <input matInput [formControl]="firstName" type="text">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Adresse e-mail</mat-label>
    <input matInput [formControl]="email" type="email">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Mot de passe</mat-label>
    <input matInput [formControl]="password" type="password">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Confirmation</mat-label>
    <input matInput [formControl]="confirmPassword" type="password">
  </mat-form-field>
  <p class="mb-4"><mat-slide-toggle [formControl]="admin">Administrateur</mat-slide-toggle></p>
  <span class="text-red-600 mb-4" *ngIf="!isPasswordMatchesConfirmation()">Votre mot de passe doit être identique à la confirmation</span>
  <button (click)="submit()" mat-flat-button [disabled]="!isValid() || !isPasswordMatchesConfirmation() || submitting" color="primary">Créer un utilisateur</button>
</div>
