<h2 class="text-3xl uppercase text-blue-900 my-4 pb-1 font-bold relative">
  <div class="border-b-red-700 border-l-red-700 h-full w-10 border-l-[.3rem] border-b-[.3rem] absolute"></div>
  <span class="ml-[0.6rem]">Liste des boutiques</span>
</h2>
<table mat-table [dataSource]="storesDataSource">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef class="w-[5vw]">ID</th>
    <td mat-cell *matCellDef="let store"  class="w-[5vw]"> {{ store.id }}</td>
  </ng-container>
  <ng-container matColumnDef="logo">
    <th mat-header-cell *matHeaderCellDef class="w-[4vw]"></th>
    <td mat-cell *matCellDef="let store"  class="w-[4vw]"><img [alt]="store.name" [src]="store.image" /> </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef class="w-1/5">Nom</th>
    <td mat-cell *matCellDef="let store"  class="w-1/5"> {{ store.name }}</td>
  </ng-container>
  <ng-container matColumnDef="country">
    <th mat-header-cell *matHeaderCellDef class="w-1/12">Pays</th>
    <td mat-cell *matCellDef="let store"  class="w-1/12"> {{ store.country }}</td>
  </ng-container>
  <ng-container matColumnDef="source">
    <th mat-header-cell *matHeaderCellDef class="w-[8vw]">Source</th>
    <td mat-cell *matCellDef="let store"  class="w-[8vw]">
      @if(store.origin === 'shoppingfeed')
      {
        <img class="w-[8vw]" alt="shoppingfeed" src="assets/logo_sf.png" />
      } @else {
        <img class="w-[8vw]" alt="lengow" src="assets/logo_lg.png" />
      }
    </td>
  </ng-container>


  <ng-container matColumnDef="loader">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let store">
      <div class="flex p-4 h-[50vh] items-center justify-center">
        <mat-spinner></mat-spinner>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell class="w-1/3" *matHeaderCellDef></th>
    <td mat-cell class="w-1/3" *matCellDef="let store">
      <div></div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[10]"
               showFirstLastButtons
               aria-label="Select page of periodic elements">
</mat-paginator>
