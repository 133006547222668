import { Component } from '@angular/core';
import {MatDialogTitle} from "@angular/material/dialog";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {LoginFormComponent} from "../user/login-form/login-form.component";

@Component({
  selector: 'app-not-logged-popup',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatTabGroup,
    MatTab,
    LoginFormComponent
  ],
  templateUrl: './not-logged-popup.component.html',
  styleUrl: './not-logged-popup.component.scss'
})
export class NotLoggedPopupComponent {

}
