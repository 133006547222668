import {Component, OnDestroy, ViewChild} from '@angular/core';
import {MatButton, MatMiniFabButton} from "@angular/material/button";
import {MatRow, MatRowDef, MatTable, MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {MatPaginator} from "@angular/material/paginator";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {debounceTime, Subject, takeUntil} from "rxjs";
import {Order} from "../../../models/Order";
import {OrderService} from "../../../services/order.service";
import {MatDialog} from "@angular/material/dialog";
import {OrderViewComponent} from "../order-view/order-view.component";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {formatDate} from "../../util/date";

@Component({
  selector: 'app-orders-list-view',
  standalone: true,
  imports: [
    MatButton,
    MatTableModule,
    MatLabel,
    MatIcon,
    MatMiniFabButton,
    MatPaginator,
    MatProgressSpinner,
    MatRow,
    MatRowDef,
    MatTable,
    MatFormField,
    MatInput,
    ReactiveFormsModule
  ],
  templateUrl: './orders-list-view.component.html',
  styleUrl: './orders-list-view.component.scss'
})
export class OrdersListViewComponent implements OnDestroy {
  private unsubscribeAll: Subject<any> = new Subject();
  protected ordersDataSource: MatTableDataSource<Order> = new MatTableDataSource<Order>([{reference: ""}]);
  displayedColumns: string[] = ["loader"];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  searchCtl = new FormControl<string>("");
  constructor(private orderService: OrderService, private dialog: MatDialog) {
    this.filterResults("")

    this.searchCtl.valueChanges
      .pipe(
        takeUntil(this.unsubscribeAll),
        debounceTime(1000)
      )
      .subscribe({
        next: value => this.filterResults(value??"")
      })
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null)
    this.unsubscribeAll.complete()
  }

  viewOrder(order: Order) {
    this.dialog.open(OrderViewComponent, {data: {order: order}})
  }

  filterResults(search: string) {
    this.displayedColumns = ["loader"];
    this.ordersDataSource = new MatTableDataSource<Order>([{reference: ""}]);
    this.orderService.searchOrders(search)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (data: any) => {
          this.ordersDataSource = new MatTableDataSource(data.datas as Order[]);
          this.ordersDataSource.paginator = this.paginator;
          this.displayedColumns = ["id", "reference", "products", "actions"]
        }
      })
  }

  computeTotal(order: Order) {
    return order.product_orders?.reduce((acc, curr) => {
      return acc + (parseFloat(curr.price) + parseFloat(curr.tax)) * curr.quantity;
    }, 0).toFixed(2);
  }

  protected readonly onabort = onabort;
  protected readonly formatDate = formatDate;
}
