import {Component, OnDestroy, ViewChild} from '@angular/core';
import {StoreService} from "../../../services/store.service";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {Store} from "../../../models/Order";
import {Subject, takeUntil} from "rxjs";
import {MatButton, MatMiniFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatPaginator} from "@angular/material/paginator";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-store-list-view',
  standalone: true,
  imports: [
    MatButton,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatMiniFabButton,
    MatPaginator,
    MatProgressSpinner,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef
  ],
  templateUrl: './store-list-view.component.html',
  styleUrl: './store-list-view.component.scss'
})
export class StoreListViewComponent implements OnDestroy {
  private unsubscribeAll: Subject<any> = new Subject();
  protected storesDataSource: MatTableDataSource<Store> = new MatTableDataSource<Store>([{}]);
  displayedColumns: string[] = ["loader"];
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private storeService: StoreService) {
    this.storeService.getStores()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (stores: any) => {
          this.storesDataSource = new MatTableDataSource(stores.datas as Store[])
          this.storesDataSource.paginator = this.paginator;
          this.displayedColumns = ["id", "logo", "name", "country", "source", "actions"]
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null)
    this.unsubscribeAll.complete()
  }
}
