import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private api: ApiService) { }

  searchProducts(search: any = null, paginator: any = null, sort: any = null): Observable<any> {
    let params = {
      gridSearchFilter: search,
      gridCurrentPage: paginator?.pageIndex,
      gridItemPerPage: paginator?.pageSize,
      gridSortField: sort?.active || '',
      gridSortDirection: sort?.direction || '',
    };
    return this.api.get("api/product/search", undefined, {
      params: params
    });
  }

  getStocks(productId: number) {
    return this.api.get("api/product/stocks", productId.toString());
  }

  createProduct(data: any) {
    return this.api.post("api/product", data);
  }

  updateProduct(data: any, productId: number) {
    return this.api.put("api/product", productId.toString(), data);
  }

  deleteProduct(productId: number) {
    return this.api.delete("api/product", productId.toString());
  }

}
