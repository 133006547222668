import {Routes} from '@angular/router';
import {UsersListViewComponent} from "./views/users-list-view/users-list-view.component";
import {WelcomeViewComponent} from "./views/welcome-view/welcome-view.component";
import {isAdminGuard} from "../services/is-admin.guard";
import {RestrictedViewComponent} from "./views/error/restricted-view/restricted-view.component";
import {ProductsListViewComponent} from "./views/products-list-view/products-list-view.component";
import {OrdersListViewComponent} from "./views/orders-list-view/orders-list-view.component";
import {StoreListViewComponent} from "./views/store-list-view/store-list-view.component";
import {WarehouseListViewComponent} from "./views/warehouse-list-view/warehouse-list-view.component";

export const routes: Routes = [
  {path: 'users', component: UsersListViewComponent, canActivate: [isAdminGuard]},
  {path: 'products', component: ProductsListViewComponent},
  {path: 'orders', component: OrdersListViewComponent},
  {path: 'stores', component: StoreListViewComponent},
  {path: 'warehouses', component: WarehouseListViewComponent},
  {path: 'home', component: WelcomeViewComponent},
  {path: 'restricted', component: RestrictedViewComponent},
  {path: '**', redirectTo: 'home'}
];
