import {Injectable} from "@angular/core";
import {AlertService} from "./alert.service";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {UserService} from "./user.service";

@Injectable({
  providedIn: "root"
})
export class GlobalErrorHandler implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private usersService: UserService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any) => {
        console.log(err)
        let error = "Une erreur s'est produite"
        if(err.status === 422)
        {
          error = err.error.detail.split(': ')[1]
        }
        else if(err.error && typeof err.error === 'string')
        {
          error = err.error
        }
        else if(err.status === 401)
        {
          if(req.url.includes("/login"))
          {
            error = "Email ou mot de passe invalide.";
          }
          else {
            this.usersService.logout();
          }
        }
        this.alertService.error(error)
        return throwError(() => error)
      })
    );
  }
}
