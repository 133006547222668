import {Component, Input} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {Alert, AlertType} from "../../../models/Alert";

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [
    MatIcon
  ],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
  @Input() alert!: Alert;

  getColor() {
    switch (this.alert.type)
    {
      case AlertType.Error: return "#700000";
      case AlertType.Info: return "#0f0f6e";
      case AlertType.Success: return "#005700";
      case AlertType.Warning: return "#edbf30";
      default: return "#AAAAAA";
    }
  }

  protected readonly AlertType = AlertType;
}
