import {Component, OnDestroy} from '@angular/core';
import {RouterLink, RouterOutlet} from '@angular/router';
import {MatIcon} from "@angular/material/icon";
import packageJson from '../../package.json';
import {MatDialog} from "@angular/material/dialog";
import {UserService} from "../services/user.service";
import {NotLoggedPopupComponent} from "./not-logged-popup/not-logged-popup.component";
import {AlertsComponent} from "./alerts/alerts.component";
import {MatButton} from "@angular/material/button";
import {Subject, takeUntil} from "rxjs";
import {User} from "../models/User";
import {NgIf} from "@angular/common";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatIcon, AlertsComponent, MatButton, NgIf, RouterLink, MatProgressSpinner],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnDestroy {
  private unsubscribeAll: Subject<any> = new Subject();
  title = 'EDI - GTCompany';
  public version: string = packageJson.version;
  protected user?: User;

  constructor(private dialog: MatDialog, protected userService: UserService) {
    if (!this.userService.getUserToken()) {
      this.dialog.open(NotLoggedPopupComponent, {disableClose: true})
    } else {
      this.userService.userInfos()
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: infos => {
            this.user = infos as User;
          }
        })
    }
  }

  logout() {
    this.userService.logout()
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null)
    this.unsubscribeAll.complete()
  }
}
