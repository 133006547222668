<div class="flex flex-col w-[80vw] h-[90vh] p-4 overflow-y-scroll">
  <h2 class="text-2xl mb-4">Commande n° {{ data.order.reference }}</h2>
  <div class="flex flex-grow">
    <div class="flex flex-col gap-8 w-[40%]">
      <div>
        <p><u>Créée le</u> {{formatDate(data.order.created_at)}}</p>
        <p><u>Modifiée le</u> {{formatDate(data.order.updated_at)}}</p>
        <p><u>Traitée le</u> {{formatDate(data.order.acknowledged_at)}}</p>
      </div>
      <div>
        <h3 class="underline font-bold mb-2">Prix total</h3>
        <p>{{computeTotal()}}</p>
      </div>
      <div>
        <h3 class="underline font-bold mb-2">Adresse de Livraison</h3>
        <p class="font-bold">{{data.order.shipping_address?.last_name}} {{data.order.shipping_address?.first_name}}</p>
        <p>{{data.order.shipping_address?.address}}</p>
        <p>{{data.order.shipping_address?.address_2}}</p>
        <p>{{data.order.shipping_address?.zip_code}} {{data.order.shipping_address?.city}}</p>
        <p>{{data.order.shipping_address?.country}}</p>
        <p class="flex items-center"><mat-icon>phone</mat-icon>{{data.order.shipping_address?.phone}}</p>
        <p class="flex items-center"><mat-icon>phone_android</mat-icon>{{data.order.shipping_address?.mobile_phone}}</p>
        <p class="flex items-center"><mat-icon>mail</mat-icon>{{data.order.shipping_address?.email}}</p>
      </div>
      <div>
        <h3 class="underline font-bold mb-2">Adresse de Facturation</h3>
        <p class="font-bold">{{data.order.order_address?.last_name}} {{data.order.order_address?.first_name}}</p>
        <p>{{data.order.order_address?.address}}</p>
        <p>{{data.order.order_address?.address_2}}</p>
        <p>{{data.order.order_address?.zip_code}} {{data.order.order_address?.city}}</p>
        <p>{{data.order.order_address?.country}}</p>
        <p class="flex items-center"><mat-icon>phone</mat-icon>{{data.order.order_address?.phone}}</p>
        <p class="flex items-center"><mat-icon>phone_android</mat-icon>{{data.order.order_address?.mobile_phone}}</p>
        <p class="flex items-center"><mat-icon>mail</mat-icon>{{data.order.order_address?.email}}</p>
      </div>
      <div>
        <h3 class="underline font-bold mb-2">Plateforme</h3>
        <p class="font-bold">{{data.order.store?.name}} ({{data.order.store?.country}})</p>
      </div>
    </div>
    <div class="ml-8 border-l-black border-l px-4 w-full flex-grow">
      <table mat-table [dataSource]="data.order.product_orders!" class="w-full">
        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef>Référence</th>
          <td mat-cell *matCellDef="let product">
            <div class="flex items-center gap-4">
              <mat-icon *ngIf="product.order_product.reference === 'LIVMP'">local_shipping</mat-icon>{{product.order_product.reference}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Quantité</th>
          <td mat-cell *matCellDef="let product">{{product.quantity}}</td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>Prix</th>
          <td mat-cell *matCellDef="let product">{{product.price}}</td>
        </ng-container>
        <ng-container matColumnDef="tax">
          <th mat-header-cell *matHeaderCellDef>Taxe</th>
          <td mat-cell *matCellDef="let product">{{product.tax}}</td>
        </ng-container>
        <ng-container matColumnDef="sub_total">
          <th mat-header-cell *matHeaderCellDef>Sous-Total</th>
          <td mat-cell *matCellDef="let product">{{((parseFloat(product.tax) + parseFloat(product.price)) * product.quantity).toFixed(2)}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['reference', 'quantity', 'price', 'tax', 'sub_total']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['reference', 'quantity', 'price', 'tax', 'sub_total'];"></tr>
      </table>
    </div>
  </div>
</div>
